/* eslint-disable */
import * as urls from '@/config/net'
import api from '@/tools/api-tools'
import { defaultGoodsImg, imgCDNPath } from '@/utils/img'

class TicketItemData {
  constructor (data) {
    this.goodsId = data.goodsId
    this.productName = data.productName
    this.productQuantity = data.productQuantity
    this.isChecked = false
    this.imgUrl = data.imgUrl
  }
}

class MultipleSaleData {
  constructor (data) {
    this.itemId = data.itemId
    this.used = data.used
    this.data = this.schemaItemData(data.data)
  }

  schemaItemData (data) {
    let listData = []
    for (let item of data) {
      listData.push(new TicketItemData({
          goodsId: item.goodsId,
          productName: item.productName,
          productQuantity: item.productQuantity,
          imgUrl: imgCDNPath(item.picUrl ? item.picUrl : defaultGoodsImg)
        })
      )
    }
    return listData
  }
}

class MultipleSale {
  constructor () {
    this.ticketCode = ''
    this.formItem = {
      MemberId: 0,
      Data: [],
      CodeId: ''
    }
  }

  schemaData (data = []) {
    let listData = []
    for (let item of data) {
      listData.push(new MultipleSaleData({
          itemId: item.itemId,
          used: item.used,
          data: item.data
        })
      )
    }
    return listData
  }

  // 获取兑换信息
  getExchangeData () {
    return api.get(`${urls.apiUrl}/industry/ticket-code/${this.ticketCode}`).then(res => {
      let data = res.data.data
      return this.schemaData(data)
    })
  }

  // 确认兑换
  submitExchange () {
    this.formItem.CodeId = this.ticketCode
    return api.post(`${urls.apiUrl}/industry/ticket-code-use`, this.formItem).then(res => {
      return res.data === 'True'
    })
  }
}

export default MultipleSale
