<template>
  <page-view>
    <template v-slot:nav>
      <nav-bar title="票据兑换" />
    </template>
    <div class="scan-container">
      <form action="javascript:return true" @submit.prevent="getTicketData">
        <input class="scan-input" type="text" placeholder="扫描票券或输入兑换码" id="myInput" v-model="multipleItem.ticketCode" />
        <i class="iconfont2 icon-saomiao- scan" @click="invokeScanCamera"></i>
      </form>
    </div>
    <!-- 列表 -->
    <div class="exchange-container">
      <div class="exchange-goods-list">
        <template v-for="itemData in exchangeData">
          <template v-for="(item, index) in itemData.data">
            <div :key="item.used + item.productName + index" class="item van-hairline--bottom" :class="{'disabled-item': itemData.used}">
              <van-checkbox class="check-box"
                            checked-color="#ff6a3c"
                            :value="item.isChecked"
                            @input="val => checkChange(val,item, itemData)"
              />
              <div style="display:flex">
                <div class="goods-icon"><img :src="item.imgUrl" alt=""></div>
                <div class="goods-desc">
                  <div>{{ item.productName }}</div>
                  <div class="text-color gray goods-number">数量：{{ item.productQuantity }}</div>
                </div>
              </div>

            </div>
          </template>
        </template>
      </div>
    </div>
    <template v-slot:footer>
      <div style="padding: 0 15px;">
        <van-button block type="warning" @click="submitExchange">确认兑换</van-button>
      </div>
    </template>
  </page-view>
</template>
<style lang="less" scoped>
  @import './index.less';
</style>
<script>
import PageView from '@/layouts/PageView'
import NavBar from '@/components/nav-bar'
import { Button, Checkbox, Toast } from 'vant'
import MultipleItem from '@/model/multiple-sale/index'

export default {
  name: 'MutipleSale',
  data () {
    const multipleItem = new MultipleItem()
    return {
      multipleItem,
      scanResult: '',
      exchangeData: [],
      isUsed: false
    }
  },
  components: {
    PageView,
    NavBar,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    [Toast.name]: Toast
  },
  created () {
  },
  mounted () {
  },
  beforeDestroy () {
  },
  methods: {
    // 清空数据
    clearData () {
      this.exchangeData = []
      this.multipleItem.ticketCode = ''
    },
    // 唤醒扫码，获取码信息
    invokeScanCamera () {
      this.multipleItem.ticketCode = ''
      window.$bridge.device.scanCamera((obj = {}) => {
        this.multipleItem.ticketCode = obj.barcode
      })
    },
    async getTicketData () {
      this.exchangeData = await this.multipleItem.getExchangeData()
      return false
    },
    submitExchange () {
      if (!this.multipleItem.ticketCode) {
        Toast('请输入兑换码')
        return
      }
      this.exchangeData.forEach(cItem => {
        cItem.data.forEach(sItem => {
          if (sItem.isChecked) {
            const obj = {}
            obj.ItemId = cItem.itemId
            obj.GoodsId = sItem.goodsId
            this.multipleItem.formItem.Data.push(obj)
          }
        })
      })
      this.multipleItem.submitExchange().then(res => {
        if (res) {
          this.clearData()
          setTimeout(() => {
            Toast('兑换成功')
          }, 330)
        }
      })
    },
    checkChange (val, item, group) {
      if (val) {
        this.exchangeData.forEach(cItem => {
          const itemId = cItem.itemId
          cItem.data.forEach(sItem => {
            if (itemId === group.itemId) {
              sItem.isChecked = false
              if (cItem.used) {
                item.isChecked = false
              } else {
                item.isChecked = val
              }
            }
          })
        })
      } else {
        item.isChecked = false
      }
    }
  },
  computed: {},
  watch: {}
}
</script>
